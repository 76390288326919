@import "assets/scss/master";

.email__content {
    position: relative;
    overflow-y: auto;
    padding: 0 24px;
    height: 40vh;
    // 11 130px 90px
    // max-height: 170px;
    textarea {
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__background__color;
            color: $dark__mode__theme__white__color !important;
        }
    }
    
    &::-webkit-scrollbar {
        display: inline;
        width: 5px !important;
    }

    &::-webkit-scrollbar-track {
        background: #fafafc;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: #e6e6ed;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #4d4b4b;
    }
}
