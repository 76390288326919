@import "assets/scss/master";
.chat__ticket__container {
    display: flex;
    overflow: hidden;
    #{$white__mode__theme__selector} {
        background-color: $light__mode__theme__background__color !important;
    }

    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color !important;
    }
    .chat__ticket--section {
        // margin-top: 0.625rem;
        flex: 1;
        border-top: 1px solid #ecebf5;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        height: 100%;
        width: 100%;
        position: relative;
        // bottom: 0;
        overflow: hidden;
        .chat__container {
            border-left: 1px solid #ecebf5;
            height: 100%;

            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
            }

            .chat__interface {
                width: 100%;
                height: 100%;
                position: relative;

                // padding-bottom: 1.25rem;
                .abs__container {
                    // position: absolute;
                    // left: 0px;
                    // top: 65px;
                    // bottom: 0px;
                    // right: 0px;
                    // margin: 0;
                    // border: 1px solid red;

                    #{$white__mode__theme__selector} {
                        background-color: $light__mode__theme__background__color !important;
                    }

                    #{$dark__mode__theme__selector} {
                        background-color: $dark__mode__theme__background__color !important;
                    }

                    height: calc(100vh - 315px);
                    

                    &.size-up {
                        height: calc(100vh - 320px);
                        display: flex;
                        flex-direction: column;
                    }

                    &.increase__height {
                        height: calc(100vh - 260px);
                        display: flex;
                        flex-direction: column;
                    }
                    
                    .scrollable-collasped-y {
                        overflow-y: scroll;
                        height: calc(100% - 5px);
                    }
                    .scrollable-y {
                        overflow-y: scroll;
                        // margin-top: 20px;
                        height: calc(100% - 120px);
                        // @include media(">=large-screen"){
                        //     height: calc(100% - 65px);

                        // }
                        /*124px => 10px on container margin + 79px top div + 15px button + 20 px this top margin*/
                    }
                }

                .chat__box {
                    // position: relative;
                    // // height: 160px;
                    // // height: 300px;
                    // height: 540px;
                    // overflow: auto;
                    // overflow-y: scroll;
                    mask-image: -webkit-gradient(
                        linear,
                        left 90%,
                        left bottom,
                        from(rgba(0, 0, 0, 1)),
                        to(rgba(0, 0, 0, 0))
                    );
                    -webkit-mask-image: -webkit-gradient(
                        linear,
                        left 90%,
                        left bottom,
                        from(rgba(0, 0, 0, 1)),
                        to(rgba(0, 0, 0, 0))
                    );
                }
            }
        }
    }

    .empty__chat {
        p {
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color !important;
            }
        }
    }
}

.tside__heading {
    text-align: center;
    padding-top: 1.063rem;
    padding-bottom: 1.25rem;
    background: #fff;
    z-index: 99;
    position: sticky;
    width: 100%;
    top: 0;

    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color !important;
    }
    h6 {
        margin: 0;
        font-size: 1rem;
        color: $black__theme_color;

        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }

    div {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}
