@import "assets/scss/master";

.ticketaa-emails {
    padding: 16px 24px;
    border-top: 1px solid $border__theme_color;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &__card {
        border-radius: 10px;
        background: #f9f9fa !important;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__color !important;
        }
        border: none !important;
        span {
            background-color: $black__theme_color !important;
        }
    }
    &__sent {
        display: flex;
        padding: 12px 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 10px;
        background: #f9f9fa;
        margin-top: 12px;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__color !important;
        }
        .form-check-input:checked {
            background-color: #1d9169 !important;
        }
        span {
            color: $black__theme_color;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
            }
        }
    }
}
