@import "assets/scss/master";

.tickets__list {
    &--container {
        // position: relative;
        // border-bottom: 1px solid #ecebf5;
        // display: flex;
        // flex-direction: column;
        // width: 100%;
        overflow-y: auto;

        // #mCSB2_container {
        //     display: flex;
        //     flex-direction: column;
        // }
    }
}
